function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function () {
    const $ = django.jQuery;

    // Set keyboard focus to text input when opening a Select2 widget.
    $(document).on('select2:open', (e) => {
        document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`).focus();
    });

    // Set tabindex to -1 on all related widget links so that they do not get in the way of
    // tabbing through the page.
    document.querySelectorAll('.related-widget-wrapper-link').forEach((el) => {
        el.tabIndex = -1;
    });
});
